<template>
    <div class="platform-dashboard-pages" v-if="!loading">
        <div class="platform-dashboard-pages__filter" :class="{'disabled-filter': !businessDashboardBankContainer}">
            <BusinessDashboardBanksFilters
                :dashboardBanksFiltersState="dashboardBanksFiltersState"
                @onApplyFilters="fetchBanksData"
            />
        </div>

        <div v-if="businessDashboardBankContainer" class="platform-dashboard-pages__content">
            <DashboardCommonHeader
                class="mb-5"
                :title="$t('Banks')"
                :description="$t('Click on any of the bank from the list for more details on it')"
                :count="businessDashboardBankContainer.totalScore.count"
                :add-button-text="$t('Manage Banks')"
                title-icon="/media/buying/icons/bank-icon.svg"
                @onSortChange="handleSortChange"
                @onClickAdd="handleClickAdd"
            />

            <DashboardStatsTable
                :score-title="$t('Company Score - All Bank(s)')"
                :score-text="$t('Based on all your Banks/filter applied')"
                :total-score="businessDashboardBankContainer.totalScore"
            >
                <template #content>
                    <div class="stats-collapse">
                        <template v-if="businessDashboardBankContainer.containerData.length">
                            <transition-group name="group-transition">
                                <div :key="item.item.id" v-for="item of businessDashboardBankContainer.containerData">
                                    <DashboardStatsCollapseItem
                                        :containerItem="item"
                                        :filterData="dashboardBanksFiltersState"
                                        :pageType="BusinessPageTypes.BANK"
                                    />
                                </div>
                            </transition-group>
                        </template>

                        <div class="section-card section-card__border" v-else>
                            <DashboardEmptyState
                                icon="/media/buying/icons/empty-state-bank.svg"
                                :title="$t('No Banks Found')"
                                :text="$t('Your company don\'t have any data for the bank(s) selected. Create a feedback session to start reviewing and get insights into your banks performance.')"
                                :button-text="$t('Add Bank')"
                                button-link="/business/settings/user-settings/overview"
                                button-icon="/media/buying/icons/plus.svg"
                                class-name="mb-10"
                            />
                        </div>
                    </div>
                </template>
            </DashboardStatsTable>
        </div>
        <CustomLoader v-else />
    </div>
</template>

<script>
import store from "@/store";
import { diagLog } from "@/core/helpers/GlobalHelper";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import BusinessDashboardBanksFilters from "@/buying-teams/pages/business/dashboard/components/BusinessDashboardBanksFilters";
import DashboardCommonHeader from "@/buying-teams/shared-components/dashboard/DashboardCommonHeader";
import DashboardStatsTable from "@/buying-teams/shared-components/dashboard/DashboardStatsTable";
import DashboardStatsCollapseItem from "@/buying-teams/shared-components/dashboard/DashboardStatsCollapseItem";
import DashboardEmptyState from "@/buying-teams/shared-components/dashboard/DashboardEmptyState";
import {
    BusinessDashboardBanksContainer
} from "@/store/models/business/dashboard/level2/BusinessDashboardBanksContainer";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import { BusinessPageTypes } from "@/store/enums/business/BusinessPageTypes";
import BusinessFiltersTypeEnum from "@/store/enums/business/BusinessFiltersTypeEnum";

export default {
    name: "Banks",
    components: {
        CustomLoader,
        DashboardEmptyState,
        DashboardStatsCollapseItem,
        DashboardStatsTable,
        DashboardCommonHeader,
        BusinessDashboardBanksFilters
    },
    data() {
        return {
            loading: true,
            businessDashboardBankContainer: null,
            BusinessPageTypes
        };
    },
    computed: {
        businessData() {
            return store.getters.business;
        },
        dashboardBanksFiltersState() {
            return store.getters.getBusinessFiltersState.dashboardBanks
        },
    },
    created() {
        setCurrentPageBreadcrumbs({
            title: this.businessData.name,
            logo: this.businessData.icon_path
        });
    },
    async mounted() {
        store.dispatch('initBusinessFiltersData', BusinessFiltersTypeEnum.DASHBOARD_BANKS);
        this.loading = false;
        await this.fetchBanksData(this.dashboardBanksFiltersState);
    },
    methods: {
        handleSortChange(sorting) {
            this.businessDashboardBankContainer.sort(sorting);
        },
        handleClickAdd() {
            this.$router.push("/business/settings/user-settings/overview");
        },
        async fetchBanksData(filter = null) {
            try {
                this.businessDashboardBankContainer = null;
                let res = await store.dispatch("fetchBusinessDashboardDataLevelTwo", {filter, type: BusinessPageTypes.BANK});
                this.businessDashboardBankContainer = new BusinessDashboardBanksContainer(res);
                diagLog("fetchBanksData DATA", res);
                diagLog("fetchBanksData MODEL", this.businessDashboardBankContainer);
            } catch (e) {
                console.error(e);
            }
        }
    }
};
</script>
