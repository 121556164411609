import {
    DashboardDataContainerBase
} from "@/store/models/shared/DashboardDataContainerBase";

export class BusinessDashboardBanksContainer extends DashboardDataContainerBase {
    constructor(obj: any) {
        super(obj);
    }
}

